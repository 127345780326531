import {
    Button,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogFooter,
} from 'react-md';
import * as IMG from '@utilities/constants/images';
import { isMobile } from 'react-device-detect';
import usePageFramework from '@utilities/hooks/usePageFramework';
import './gettingStarted.css';
import { CloseSVGIcon } from '@react-md/material-icons';
import { deriveOrganizerActivity } from '@utilities/organizerData';
import api from '@utilities/claApi';

function GettingStartedModal(props) {
    const { setCurrentStep, mobileSaveEntry, saveEntryExperience, setIsOpen } = props;
    const { selectState, REDUX, dispatch, history, NAV, ACTION, organizerId, } = usePageFramework();
    const isAdmin = selectState(REDUX.IS_ADMIN);
    const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
    const dashboard = selectState(REDUX.DASHBOARD);
    const categories = dashboard ? dashboard : [];

    const handleExploreTaxQuestions = () => {
        if ((!isAdmin && !isPractitioner) && isMobile) {
            mobileSaveEntry(true);
        } else {
            setCurrentStep(1);
        }
    };

    const handleSkipToDashboard = () => {
        const completedStep = 1;
        const json = {
            entryExperience: {
                completedStep: completedStep,
            },
            status: 'In Progress'
        };
        const derivedOrganizerData = deriveOrganizerActivity(json, new Date());
        const payload = Object.assign({}, json, derivedOrganizerData);
        api.put(`/organizers/${organizerId}`, payload).then(() => {
            dispatch(ACTION.setCompletedEntryStep(completedStep));
        }).then(() => {
            return api.get(`organizers/${organizerId}/dashboard`);
        }).then((dashboardResponse) => {
            const { id: dashboardId } = dashboardResponse.data;
            const dashboardUpdateBody = { dashboard: categories };
            return api.put(`organizers/${organizerId}/dashboard/${dashboardId}`, dashboardUpdateBody);
        }).catch((error) => {
            console.error(error);
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle('An unexpected error occured.'));
            dispatch(ACTION.setCustomDialogMsg('Please try again later.'));

        }).finally(() => {
            dispatch(ACTION.setProgressVisible(false));
            setIsOpen(false);
            dispatch(ACTION.setHasSkipEntryStep(true));
            history.push(NAV.DASHBOARD)
        });
    }

    return (
        <>
            {isMobile ? <img src={IMG.CLA_LOGO_MOBILE} alt="CLA navy colored logo" className="entryHeaderSilIcon" style={{ width: '70px', height: '70px', marginTop: '70px' }}></img> : null}
            <div>
                {!isMobile ? <img src={isMobile ? IMG.CLA_LOGO_MOBILE : IMG.CLA_NAVY_LOGO} alt="CLA navy colored logo" className="entryHeaderSilIcon"></img> : null}
                <DialogHeader>
                    <DialogTitle id="entry-experience-dialog-title" className={isMobile ? "entry-experience-mobile-title" : "entry-experience-title"}
                    >
                        Welcome to the {isMobile ? <label>1040 exchange</label> : <span className="entry-experience-app-title">1040 Exchange</span>}
                    </DialogTitle>
                </DialogHeader>
            </div>
            <DialogContent className="entry-modal-content entry-modal-content-mobile" style={isMobile ? { height: "50px", borderRadius: '10px', minHeight: '40%' } : null}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', height: '560px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '617px', height: '111px' }}>
                        <img src={IMG.ENTRY_GETTING_STARTED_ASSETS} alt="CLA navy colored logo" className=""></img>
                        <div style={{ width: '473px', height: '83px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div>
                                <p style={{ fontSize: '24px', color: '#2E334E', fontWeight: '400', margin: '0px' }}>Let's Get Started</p>
                            </div>
                            <div >
                                <p style={{ fontSize: '18px', color: '#4B4B4B', fontWeight: '400', margin: '0px', marginBottom: '1px' }}>Choose below how you want to continue.</p>
                                <p style={{ fontSize: '18px', color: '#4B4B4B', fontWeight: '400', margin: '0px' }}>*Explore tax questions is recommended for new users.</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '348px', height: '315px', border: '1px solid rgba(151, 151, 151, 0.25)', backgroundColor: '#F3F3F3', padding: '33px', borderRadius: '4px', marginRight: '20px', position: 'relative' }}>
                            <p style={{ fontSize: '16px', color: '#2E334E', fontWeight: '600', lineHeight: '22px', margin: '0px', marginBottom: '25px' }}>
                                I want to explore questions about my tax year
                            </p>
                            <p style={{ fontSize: '16px', color: '#2E334E', fontWeight: '400', lineHeight: '22px',  }}>
                                Review questions to help guide you through your tax year. Then review any prior tax return data in Required and Recommended cards on your dashboard.
                            </p>
                            <div style={{ position: 'absolute', bottom: '33px'}}>
                                <Button
                                    data-testid="acceptButtonId"
                                    themeType="contained"
                                    className="acceptContinueButton"
                                    onClick={handleExploreTaxQuestions}
                                >
                                    Explore Tax Questions
                                </Button>
                            </div>
                        </div>
                        <div style={{ width: '348px', height: '315px', border: '1px solid rgba(151, 151, 151, 0.25)', backgroundColor: '#F3F3F3', padding: '33px', borderRadius: '4px', position: 'relative' }}>
                            <p style={{ fontSize: '16px', color: '#2E334E', fontWeight: '600', lineHeight: '22px', margin: '0px', marginBottom: '25px' }}>
                                I want to skip questions and provide my information
                            </p>
                            <p style={{ fontSize: '16px', color: '#2E334E', fontWeight: '400', lineHeight: '22px' }}>
                                Go directly to your dashboard to upload documents and complete Required cards.
                            </p>
                            <div style={{ position: 'absolute', bottom: '33px'}}>
                                <Button
                                    data-testid="acceptButtonId"
                                    themeType="contained"
                                    className="acceptContinueButton"
                                    onClick={handleSkipToDashboard}
                                >
                                    Skip to My Dashboard
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogFooter className='entryExperienceFooter fixed-footer'>
            </DialogFooter>
        </>

    );
}

export default GettingStartedModal;