import { Button, DialogContent, Divider } from 'react-md';
import { Grid, GridCell } from '@react-md/utils';
import { ChevronRightSVGIcon } from '@react-md/material-icons';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import { isMobile } from 'react-device-detect';
import './welcomeIntro.css';

function EntryWelcomeIntro(props) {
	const { moveAgreement, showPriorTaxInformation, showUploadDocument } = props;
	const { selectState, REDUX } = usePageFramework();
	const priorYearDetails = selectState(REDUX.PRIOR_YEAR_DETAILS);

	const currentOrganizer = priorYearDetails?.priorYearLookup?.find(year => year.value === currentYear);
	const styles = {
		backgroundImage: `url(${IMG.ENTRY_EXPERIENCE_TOP_LAYER}), url(${IMG.ENTRY_EXPERIENCE_MIDDLE_LAYER}), url(${IMG.ENTRY_EXPERIENCE_BOTTOM_LAYER}), url(${IMG.CLA_WELCOME_BG})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center, center, center, 265px',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	};

	const mobileStyles = {
		backgroundImage: `url(${IMG.ENTRY_EXPERIENCE_MOBILE_BACKGROUND}), url(${IMG.ENTRY_EXPERIENCE_MOBILE_BACKGROUND_RECTANGLE})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		position: 'relative',
	};

	return (
		<>
			<DialogContent style={isMobile ? mobileStyles : styles}>
				<Grid style={{
					overflow: 'hidden'
				}}>
					<GridCell colSpan={12} style={{ marginTop: 140 }}><img src={isMobile ? IMG.CLA_LOGO_MOBILE : IMG.CLA_WHITE_LOGO} alt="CLA logo" className={isMobile ? "mobileClaLogo" : null} /></GridCell>
					<GridCell colSpan={8} style={{ marginTop: 30 }}>
						<div className={isMobile ? "entryExperienceMobileHeaderText" : "entryExperienceHeaderText"}>Welcome to the 1040 Exchange</div>
						<Divider className={isMobile ? "entryExperienceMobileDivider" : "entryExperienceDivider"} />
					</GridCell>
					<GridCell colSpan={12}>
						<p className="entryExperienceText"
							style={isMobile ?
								{
									display: "flex",
									fontSize: "50px",
									maxWidth: "100%",
									marginBottom: "150px",
								} :
								{
									display: "flex",
									lineHeight: "24px",
									fontSize: "21px",
									marginTop: "30px",
									fontWeight: "400"
								}}>
							Provide My Tax Information
							<Button
								onClick={() => { moveAgreement(currentOrganizer?.id); }}
								id="icon-button-chevron"
								data-testid="current-year-tax-info-link"
								buttonType="icon"
								theme="clear"
								style={isMobile ? { backgroundColor: 'white', width: 60, height: 60, marginLeft: "15px", left: -130, top: 30 }
									: { backgroundColor: 'white', width: 25, height: 25, marginLeft: "15px" }}
							>
								<ChevronRightSVGIcon style={isMobile ? { width: 60, height: 60 } : null} />
							</Button>
						</p>
						<p className="entryExperienceText"></p>
					</GridCell>
				</Grid>
			</DialogContent>
		</>
	);
}

export default EntryWelcomeIntro;