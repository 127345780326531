import usePageFramework from "@utilities/hooks/usePageFramework";
import "./navbarFooter.css";
import _ from "lodash";
import { ARROW_RIGHT, ARROW_LEFT } from "@utilities/constants/images";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ReactMDLink } from "react-md";

const NavBarFooter = () => {
  const { REDUX, selectState } = usePageFramework();
  const allCards = selectState(REDUX.NAVBAR_CARDS);
  const currentKey = selectState(REDUX.CURRENT_CARD_KEY);

  let cardsArr =
    allCards &&
    allCards.map((category) => {
      return category?.cards;
    });

  let previousRoute = '/';
  let nextRoute = '/';
  let currentIndex;
  cardsArr = _.flatten(cardsArr);
  cardsArr &&
    cardsArr.forEach((element, index) => {
      if (element?.key === currentKey) {
        currentIndex = index;
        previousRoute = cardsArr[index - 1]?.route;
        nextRoute = cardsArr[index + 1]?.route;
      }
    });

  const Link = (props) => {
    return (
      <ReactMDLink
        component={ReactRouterLink}
        {...props}
        style={{ textDecoration: "none", color: "black" }}
      />
    );
  };

  return (
    <div className="footer-navbar font-style">
        <div className="min-width">
      {currentIndex !== 0 && (
        <Link
          to={previousRoute}
          data-testid= "navPrevious"
          onClick={() => window.scrollTo(0, 0)}
          className={`item-flex`}
        >
          <span className="icon-circle">
            <img src={ARROW_LEFT} alt="arrow-left" />
          </span>
          <span className="font-style">Move to Previous Card</span>
        </Link>
      )}
      </div>
      <Link className="item-flex" to="/dashboard">
        <span className="font-style text-underline">Return to Dashboard</span>
      </Link>
      <div className="min-width">
      {currentIndex+1 !== cardsArr?.length && (
        <Link
          to={nextRoute}
          data-testid= "navNext"
          onClick={() => window.scrollTo(0, 0)}
          className={`item-flex`}
        >
          <span className="font-style">Move to Next Card</span>
          <span className="icon-circle">
            <img src={ARROW_RIGHT} alt="arrow-left" />
          </span>
        </Link>
      )}
      </div>
    </div>
  );
};

export default NavBarFooter;
