
import { Button } from 'react-md';

import { DASHBOARD_TAX_RETURN } from '@utilities/constants/images';

import './style.css';
import { hasPendingTaxReturnStatus } from '@utilities/services/taxReturnService';

function TaxReturnBanner(props) {
    const { taxReturnStatus } = props;

    return hasPendingTaxReturnStatus(taxReturnStatus)
        ? (
            <div className={'tax-return-banner'}>
                <div className={'tax-return-icon-text'}>
                    <img alt={'TAX RETURN'} src={DASHBOARD_TAX_RETURN} />
                    <div className={'tax-return-base-text'}>
                        <div className={'header-text'}>Your tax documents are ready to sign.</div>
                        <div>Since your last visit to the 1040, a few documents could use your final signature. Thank you.</div>
                    </div>
                </div>
                <Button
                    themeType="contained"
                    className={'entryNextButton uploadButton sign-tax-return-btn'}
                >
                    Sign your tax return
                </Button>
            </div>
        ) : null;
}

export default TaxReturnBanner;