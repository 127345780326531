// common modules
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { AddSVGIcon, RemoveSVGIcon, MoreVertSVGIcon } from '@react-md/material-icons';
import { DropdownMenu, MenuItem } from 'react-md';
import { TOP_LEFT_ANCHOR, useToggle } from '@react-md/utils';
import { Button } from "@react-md/button";
import EmailChecklistDialog from '@components/dialog/emailChecklistDialog';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from "@react-md/dialog";

// custom modules
import '@components/OrganizerList.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import CustomNotesPanel from '@components/notes/CustomNotesPanel';
import DownloadNewFiles from '@components/newFile/downloadNewFiles.js';
import InPrepCheckBox from '@components/InPrepCheckBox.js';
import api from '@utilities/claApi';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import * as STR from '@utilities/constants/notes';
import * as PRACTITIONERDASHBOARD from '@utilities/constants/practitionerDashboard.js';
import * as STRING from '@utilities/constants/strings'

// MUI imports
import { Box, Tooltip } from '@mui/material';
import {
  DataGridPro, gridClasses,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF, getGridStringOperators
} from '@mui/x-data-grid-pro';
//MUI pagination
import CustomToolbar from '@components/organizerList/customToolbar';
import CustomNoResultsOverlay from '@components/organizerList/customNoResultsOverlay';
import { deriveStatus, deriveActivity } from '@utilities/organizerData/deriveOrganizerData';
import EmailChecklistMenuItem from './organizerList/emailChecklistMenuItem';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';

function OrganizerList(props) {
  const {
    organizers, totalResults, paginatedLoad,
    currentPageNumber = 0,
    handleFilterModelChange = () => { },
    handleSortModelChange = () => { },
    syncParentData = () => { },
    hasLockPermission = false,
    handlePageSizeChange,
    handlePageModelChange,
    pageSize,
    currentFilterModel,
    currentSortModel,
    onDownload,
  } = props;
  const {
    history,
    dispatch,
    NAV,
    ACTION,
    clearFormState,
    REDUX,
    selectState,
    setEntityFormState,
    updateUploadList,
    downloadFile,
    downloadAllFiles,
  } = usePageFramework();

  const [showDialog, enableDialog, disableDialog] = useToggle(false);
  const [showEmailChecklistDialog, enableEmailChecklistDialog, disableEmailChecklistDialog] = useToggle(false);
  const [rowData, setRowData] = useState(organizers ?? []);
  const [expandedRows, setExpandedRows] = useState([]);

  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);

  const [dialog, setDialog] = useState({
    title: '',
    message: ''
  });

  const [selectedOrganizerId, setSelectedOrganizerId] = useState();

  useEffect(() => {
    setRowData(organizers);
  }, [organizers]);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const filterHighlight = currentFilterModel?.items?.[0]?.value ? 'filterButtonHighlight' : 'filterButton';

  const handleEmailChecklistToggleClick = (organizerId) => {
    enableEmailChecklistDialog();
    setSelectedOrganizerId(organizerId);
  }

  const changeData = (id, newData) => {
    const dataDeepCopy = _.cloneDeep(rowData);

    dataDeepCopy.forEach((item) => {
      if (item.id === id) {
        newData.forEach((newChangeData) => {
          item[newChangeData.column] = newChangeData.value;
        });
      }
    });

    setRowData(dataDeepCopy);
    syncParentData(dataDeepCopy);
  };


  const showProgressDialog = () => {
    dispatch(ACTION.setProgressText('Loading Client Data...'));
    dispatch(ACTION.setProgressVisible(true));
  };

  const hideProgressDialog = () => {
    dispatch(ACTION.setProgressText(''));
    dispatch(ACTION.setProgressVisible(false));
  };

    // Function to return documents/uploads associated with the organizer when clicked
    const loadNewDocuments = async (organizerId) => {
      const response = await api.get(`organizers/${organizerId}/documents`);
      const documents = response.data.results.filter((doc) => doc.isNew === true);
      return documents;
    };

  const handleDownload = async (documents, organizerId) => {
    const timestamp = new Date();
    let downloadSuccessful = true;
    let handleActiveReturn = false;
    if (documents.length >= 5) {

      dispatch(ACTION.setProgressVisible(true));
      dispatch(ACTION.setUploadProgressVisible(true));
      dispatch(ACTION.setProgressText(STRING.DOWNLOADED_FILES_PROGRESS));
      try {
        await downloadAllFiles(false, {}, documents, handleActiveReturn);
        dispatch(ACTION.setCustomDialogMsg(STRING.DOWNLOADED_FILES_COMPLETED));
      } catch(err) {
        dispatch(ACTION.setCustomDialogMsg(STRING.DOWNLOADED_FILES_ERRORED));
        downloadSuccessful = false;
      } finally {
        dispatch(ACTION.setCustomDialogTitle(''));
        dispatch(ACTION.setProgressVisible(false));
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setUploadProgressVisible(true));
      };

    } else {
      try {
        const downloadPromises = documents.map(async (doc) => {
          await downloadFile(doc);
          doc.isNew = false;
          doc.downloaded_on = `${timestamp.toJSON()}`;
        });
        await Promise.all(downloadPromises);
      } catch(err) {
        downloadSuccessful = false;
      }
      

    }
    
    if(downloadSuccessful) {
      setRowData((prevRowData) => {
        return prevRowData.map((org) => {
          if (org.id === organizerId) {
            const newDocumentCount = 0;
            return {
              ...org,
              newDocumentCount
            };
          }
          return org;
        })
      })
    }   
  };

  const handleDownloadClick = async (event, org) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(ACTION.setOrganizerId(org.id));
    try {
      const newDocuments = await loadNewDocuments(org.id);
      await handleDownload(newDocuments, org.id);
      await onDownload();
    } catch (err) {
      console.error('Failed to retrieve uploads', err)
    }
  };

  const loadOrganizer = async (targetOrganizer) => {
    const { id: organizerId } = targetOrganizer;
  
    if (!organizerId) {
      console.error('No organizerId available');
      return;
    }
  
    showProgressDialog();
    clearFormState();
  
    try {
      // Fetch related organizers, main organizer data, notes, dashboard, and documents all at once
      // GROWTH: Consider handling organizer loadings in a separate component
      const [relatedOrganizersResponse, organizerResponse, notesResponse, dashboardResponse, documentsResponse] = await Promise.all([
        api.get(`organizers?filter=client.number eq ${targetOrganizer?.client?.number}`),
        api.get(`/organizers/${organizerId}`),
        api.get(`/organizers/${organizerId}/notes`),
        api.get(`/organizers/${organizerId}/dashboard`),
      ]);
  
      const relatedOrganizers = relatedOrganizersResponse.data?.results;
      const organizerData = organizerResponse.data;
      const notesData = notesResponse.data.results;
      const dashboardData = dashboardResponse.data;
  
      // Handle related organizers and prior year lookup
      const priorYearLookup = relatedOrganizers
        .filter(organizer => organizer?.client?.number === targetOrganizer?.client?.number)
        .map(organizer => ({
          name: organizer.year,
          value: organizer.year,
          id: organizer.id,
        }));
  
      const priorYearOrganizer = priorYearLookup.find(priorYearOrganizer => 
        priorYearOrganizer?.value === targetOrganizer?.year - 1 && organizerId !== priorYearOrganizer?.id
      );
  
      dispatch(ACTION.setPriorYearDetails({
        priorYear: priorYearOrganizer?.value || false,
        priorOrganizerId: priorYearOrganizer?.id || false,
        priorYearLookup,
      }));
  
      // Extract data from the organizer response
      const { forms, locked, client, status, year, entryExperience, taxpayer, spouse, dashboardVersion } = organizerData;
  
      dispatch(ACTION.setFormNotes(notesData)); // Set organizer notes
      dispatch(ACTION.setCompletedEntryStep(entryExperience?.completedStep > 0)); // Completed step
  
      // Get prior year data for the organizer
      const priorData = await api.get(`/organizers/${organizerId}/prior`)
        .then(response => response.data.data.taxData.priorYear)
        .catch(err => {
          console.error(err);
          return null;
        });
  
      // Update upload list (no duplication here)
      await updateUploadList(organizerId).catch(err => console.error('Failed to retrieve uploads', err));
  
      // Process dashboard data (only once)
      let dashboardCopy = _.cloneDeep(dashboardData.dashboard ?? defaultDashboard);
      if (priorData) {
        dashboardCopy = setRequiredForms(priorData, dashboardCopy);
        await api.put(`organizers/${organizerId}/dashboard/${dashboardData.id}`, { dashboard: dashboardCopy });
      }
  
      // Dispatch dashboard data once
      dispatch(ACTION.setDashboard(dashboardCopy));
      dispatch(ACTION.setDashboardId(dashboardData.id));
  
      // Set form data into Redux
      const formKeys = [];
      forms.forEach((form) => {
        if (!formKeys.includes(form.key)) {
          dispatch(ACTION.setForm(form.key, form.data));
          formKeys.push(form.key);
        }
      });
  
      // Load vehicles if needed
      if (priorData && !forms.some(form => form.key.startsWith(REDUX.VEHICLE))) {
        const vehicleFormData = loadVehicleData(priorData, year);
        if (vehicleFormData) {
          Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
            setEntityFormState(vehicleKey, vehicleForm, organizerId, dashboardCopy);
          });
        }
      }
  
      // Set organizer metadata and documents
      const clientInfo = {
        displayName: client?.name,
        clientNumber: client?.number ?? '',
        currentYear: year,
        formStatus: status,
      };

      dispatch(ACTION.setPriorYearData(priorData));
      dispatch(ACTION.setTaxReturnStatus(organizerData.taxReturnStatus));
      dispatch(ACTION.setOrganizerId(organizerId));
      dispatch(ACTION.setActiveReturn(clientInfo));
      dispatch(ACTION.setLockForms(locked || false));
      dispatch(ACTION.setYear(year));
      dispatch(ACTION.setIsSaveSuccess(organizerId ? true : false));
      if (taxpayer) dispatch(ACTION.setTaxpayerEmail(taxpayer.email || ''));
      if (spouse) dispatch(ACTION.setSpouseEmail(spouse.email || ''));
      dispatch(ACTION.setDashboardVersion(dashboardVersion));
    } catch (err) {
      console.error(err);
    } finally {
      history.push(NAV.DASHBOARD); // Ensure navigation to the dashboard after loading
      hideProgressDialog();
    }
  };
  
  

  const preventBubbleEvent = (event) => {
    event.stopPropagation();
  };

  const buildNameOnHover = (employee) => {
    if (!employee) return '';

    let name = '';

    if (employee.last) name = employee.last;
    if (employee.first) {
      if (name) name += ', ';

      name += employee.first;
    }
    if (employee.middle && employee.first) name += ` ${employee.middle[0]}`;

    return name
  };

  const rows = [];
  const names = [];
  const csl = [];
  const signer = [];
  const personInCharge = [];
  const exchangeStatus = [];
  const lastActivity = [];
  const lockCheckbox = [];
  const loadOrganizerInfo = [];
  const newDocumentCount = [];

  rowData.forEach((organizer, index) => {
    loadOrganizerInfo.push(
      {
        id: organizer.id,
        organizer: organizer
      }
    );
    names.push(
      {
        id: organizer.id,
        name: organizer?.client?.name
      }
    );
    csl.push(
      {
        id: organizer.id,
        csl: organizer?.client?.csl,
        number: organizer?.client?.number
      }
    );
    signer.push(
      {
        id: organizer.id,
        signer: organizer.signer
      }
    );
    personInCharge.push(
      {
        id: organizer.id,
        inCharge: organizer.inCharge
      }
    );
    exchangeStatus.push(
      {
        id: organizer.id,
        exchangeStatus: organizer.customStatus
      }
    );
    lastActivity.push(
      {
        id: organizer.id,
        lastActivity: organizer.customDate
      }
    );
    lockCheckbox.push(
      {
        id: organizer.id,
        organizer: organizer,
        index: index
      }

    );
    newDocumentCount.push(
      {
        id: organizer.id,
        organizer: organizer,
        index: index
      }

    );
    rows.push(
      {
        id: organizer.id,
        orgId: `${organizer.id}`,
        name: `${organizer?.client?.name}`,
        csl: `${organizer?.client?.number} ${organizer?.client?.csl}`,
        signer: `${organizer?.signer?.initials || ''}`,
        inCharge: `${organizer?.inCharge?.initials || ''}`,
        exchangeStatus: `${deriveStatus(organizer)}`,
        lastActivity: `${deriveActivity(organizer)}`,
        lock: `${organizer.locked}`,
        newDocumentCount: `${organizer.newDocumentCount}`
      }
    );
  });

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    PRACTITIONERDASHBOARD.OPERATORS_LIST.includes(value),
  );


  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => {
        const hasNotes = params.row?.notes?.find(note => note.key === STR.PRAC_DASH_NOTE_KEY && note.value?.trim() !== '')

        return (
          <div className={'addRemoveIconContainer'} style={hasNotes ? { backgroundColor: '#E2E4EE' } : null}>
            {params.value ? <RemoveSVGIcon className="denseRowBtn" /> :
              <AddSVGIcon className="denseRowBtn" style={hasNotes ? { fill: '#2E334E' } : null} />}
          </div>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      pinnable: false,
      filterOperators,
      renderCell: (params) => {
        let name = ""
        let documentCount;
        let isLocked = false;
        let indexNum = '';
        let indexDocumentNum = ''
        let orgId = '';
        let org;

        lockCheckbox.forEach((lockbox) => {
          if (params.id === lockbox.id) {
            indexNum = lockbox.index;
            isLocked = lockbox.organizer.locked
          }
        })

        names.forEach((orgName) => {
          if (params.id === orgName.id) {
            name = orgName.name
          }
        })

        newDocumentCount.forEach((document) => {
          if (params.id === document.id) {
            indexDocumentNum = document.index;
            documentCount = document.organizer.newDocumentCount;
          }
        })

        loadOrganizerInfo.forEach((orgInfo) => {
          if (params.id === orgInfo.id) {
            orgId = orgInfo.organizer.id
            org = orgInfo.organizer
          }
        })

        return (
          <div key="organizer-list-row-cell-name" style={{ overflowWrap: "break-word", whiteSpace: "normal" }}>
            {documentCount > 0 && (
              <DownloadNewFiles
                className="downloadNewFiles above"
                total={documentCount}
                onClick={(event) => handleDownloadClick(event, org)}>
              </DownloadNewFiles>)}
            {name}
          </div>

        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-name`} key="name" style={{ overflowWrap: "break-word", whiteSpace: "normal" }} >
            Name
          </div>
        )
      }
    },
    {
      field: 'cslClientNumber',
      headerName: 'CSL - Client',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      pinnable: false,
      filterable: false,
      filterOperators,
      renderCell: (params) => {
        let clientNumber = ""
        let clientCSL = ""

        csl.forEach((cslClient) => {
          if (params.id === cslClient.id) {
            clientNumber = cslClient.number
            clientCSL = cslClient.csl
          }
        })

        return (
          <div key="organizer-list-row-cell-clientCsl">
            <div>{clientNumber}</div>
            <div>{clientCSL}</div>
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-clientCsl`} key="clientCsl">
            CSL - Client
          </div>
        )
      }
    },
    {
      // This column is hidden via columnVisibilityModel
      field: 'clientNumber',
      headerName: 'Client Number',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      pinnable: false,
      hide: true,
      sortable: false,
      filterOperators,
      renderCell: (params) => {
        let clientNumber = ""

        csl.forEach((cslClient) => {
          if (params.id === cslClient.id) {
            clientNumber = cslClient.number
          }
        })

        return (
          <div key="organizer-list-row-cell-clientNumber">
            <div>{clientNumber}</div>
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-clientNumber`} key="clientNumber">
            Client Number
          </div>
        )
      }
    },
    {
      // This column is hidden via columnVisibilityModel
      field: 'csl',
      headerName: 'CSL',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      pinnable: false,
      hide: true,
      sortable: false,
      filterOperators,
      renderCell: (params) => {
        let clientCsl = ""

        csl.forEach((cslClient) => {
          if (params.id === cslClient.id) {
            clientCsl = cslClient.csl
          }
        })

        return (
          <div key="organizer-list-row-cell-clientCsl">
            <div>{clientCsl}</div>
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-clientCsl`} key="clientCsl">
            CSL
          </div>
        )
      }
    },
    {
      field: 'signer',
      headerName: 'Signer',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      pinnable: false,
      filterOperators,
      renderCell: (params) => {
        let signerName = '';

        signer.forEach((sign) => {
          if (params.id === sign.id) {
            signerName = buildNameOnHover(sign.signer)
          }
        })

        return (
          <div key="organizer-list-row-cell-signer">
            <Tooltip title={signerName} >
              <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
          </div>
        )

      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-signer`} key="signer">
            Signer
          </div>
        )
      }
    },
    {
      field: 'inCharge',
      headerName: 'In Charge',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      pinnable: false,
      filterOperators,
      renderCell: (params) => {
        let inChargeName = '';

        personInCharge.forEach((charge) => {
          if (params.id === charge.id) {
            inChargeName = buildNameOnHover(charge.inCharge)
          }
        })

        return (
          <div key="organizer-list-row-cell-inCharge">
            <Tooltip title={inChargeName} >
              <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-inCharge`} key="inCharge">
            In Charge
          </div>
        )
      }
    },
    {
      field: 'exchangeStatus',
      headerName: 'Exchange Status',
      cellClassName: 'clientInfoText clientTableCell',
      width: 200,
      pinnable: false,
      filterOperators,
      renderCell: (params) => {
        let status = ""

        exchangeStatus.forEach((updateStatus) => {
          if (params.id === updateStatus.id) {
            status = updateStatus.exchangeStatus || params?.row?.exchangeStatus
          }
        })

        return (
          <div key="organizer-list-row-cell-status">
            {status}
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-status`} key="status">
            Exchange Status
          </div>
        )
      }
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      cellClassName: 'clientInfoText clientTableCell',
      width: 150,
      filterable: false,
      pinnable: false,
      renderCell: (params) => {
        let activity = ""

        lastActivity.forEach((activityTime) => {
          if (params.id === activityTime.id) {
            activity = activityTime.lastActivity || params?.row?.lastActivity
          }
        })

        return (
          <div key="organizer-list-row-cell-updatedOn">
            {activity}
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-updatedOn`} key="updatedOn">
            Last Activity
          </div>
        )
      }
    },
    {
      field: 'lock',
      headerName: 'Lock',
      cellClassName: 'clientInfoText clientTableCell lockColumn',
      headerClassName: 'lockHeader',
      width: 150,
      filterable: false,
      pinnable: false,
      renderCell: (params) => {
        let indexNum = '';
        let organizerData = {};

        lockCheckbox.forEach((lockbox) => {
          if (params.id === lockbox.id) {
            indexNum = lockbox.index;
            organizerData = { ...lockbox.organizer }
          }
        })
        return (
          <div key={`organizer-list-row-cell-locked`} className='clientInfoText clientTableCell' onClick={preventBubbleEvent}>
            <InPrepCheckBox index={indexNum} data={organizerData} changeData={changeData} disabled={!hasLockPermission} />
          </div>
        )
      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-locked`} key="locked">
            Lock
          </div>
        )
      }
    },
    {
      field: 'eSignature',
      headerName: '',
      cellClassName: 'clientInfoText clientTableCell',
      width: 100,
      pinnable: false,
      filterOperators,
      renderCell: (params) => {
        let eSignature = '';

      },
      renderHeader: (params) => {
        return (
          <div data-testid={`pracDash-tableCell-eSignature`} key="eSignature">
            {/* eSignature */}
          </div>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      cellClassName: 'clientInfoText clientTableCell menuColumn',
      headerClassName: '',
      width: 135,
      disableClickEventBubbling: true,
      filterable: false,
      pinnable: false,
      renderCell: (params) => {
        let indexNum = '';
        lockCheckbox.forEach((lockbox) => {
          if (params.id === lockbox.id) {
            indexNum = lockbox.index;
          }
        })
        return (
          <div className="headerCardMoreBtnContainer">
            <DropdownMenu
              id={`organizer-row-ellipsis-${indexNum}`}
              buttonType="icon"
              aria-label={`organizer-row-ellipsis-${indexNum}`}
              anchor={TOP_LEFT_ANCHOR}
              buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
            >
              {menuItems(params?.row)}
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  const menuItems = (row) => {
    const menuItems = [];
    if (isAdmin || isPractitioner) {
      menuItems.push(
        <MenuItem
          key='resend-client-welcome-email'
          className='client-welcome-email'
          onClick={() => {
            setDialog({
              title: 'Resend Welcome E-mail Invitation',
              message: 'Resending Welcome E-mail Invitation to client...',
              allowClose: false
            });

            enableDialog();

            api.get(`/organizers/${row.id}/users`).then((response) => {
              return response.data.results // returns array of users
            }).then((users) => {
              return users.filter(user => user.id && user.email && !user.email.endsWith('@claconnect.com'));
            }).then((externalUsers) => {
              // send the cdh welcome email per user
              const emailPromises = externalUsers.map((externalUser) => {
                return api.put(`/organizers/${row.id}/users/${externalUser.id}/invite`, { resend: true });
              });

              return Promise.allSettled(emailPromises);
            }).then((results) => {
              const emailsFailed = results.filter((result) => result.status === 'rejected');

              if (emailsFailed.length) {
                console.log(`Failed to send ${emailsFailed.length} welcome email(s)`);
              }
            }).then(() => {
              setDialog({
                title: 'Resend Welcome E-mail Invitation',
                message: 'Request to resend welcome e-mail invitation has been sent.',
                allowClose: true
              });
            }).catch((error) => {
              console.log(error);
              setDialog({
                title: 'An error has occured',
                message: 'Please contact IT for support',
                allowClose: true
              });
            }).finally(() => {
              dispatch(ACTION.setProgressVisible(false));
            });
          }}
        > {STRING.RESEND_CLIENT_WELCOME_EMAIL}
        </MenuItem>
      );

      menuItems.push(
        <EmailChecklistMenuItem
          callback={handleEmailChecklistToggleClick}
          organizerId={row.id}
        />
      )
    }
    return menuItems;
  };

  function muiLoadOrganizer(params) {
    const orgInfo = loadOrganizerInfo.find(loadOrg => params.id === loadOrg.id);
    return loadOrganizer(orgInfo?.organizer);
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 30 : 6,
      bottom: params.isLastVisible ? 9 : 6,
    };
  }, []);

  const renderResendInviteDialog = () => {
    return (
      <Dialog
        id="organizer-action-dialog"
        role="alertdialog"
        visible={showDialog}
        onRequestClose={() => { }}
        aria-labelledby="organizer-action-dialog-title"
        data-testid="organizer-action-dialog-title">
        <DialogHeader>
          <DialogTitle>{dialog.title}</DialogTitle>
        </DialogHeader>
        <DialogContent>{dialog.message}</DialogContent>
        <DialogFooter>
          <Button id="organizer-action-dialog-confirm" theme="warning" disabled={!dialog.allowConfirm} onClick={dialog.onConfirm}>Confirm</Button>
          <Button id="organizer-action-dialog-close" onClick={disableDialog} disabled={!dialog.allowClose}>
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  const renderEmailChecklistDialog = () => {
    return (selectedOrganizerId && showEmailChecklistDialog
      && <EmailChecklistDialog
        organizerId={selectedOrganizerId}
        disableEmailChecklistDialog={disableEmailChecklistDialog}
      />
    );
  }

  return (
    <>
      <Box sx={{ height: 'auto', width: '100%' }} data-testid='pracdash-columnheader'>
        <DataGridPro
          loading={paginatedLoad}
          filterMode={'server'}
          disableMultipleColumnsFiltering={false}
          filterModel={currentFilterModel}
          onFilterModelChange={handleFilterModelChange}
          filterDebounceMs={300}
          getRowSpacing={getRowSpacing}
          onRowClick={(params) => {
            muiLoadOrganizer(params)
          }}
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            csl: false,
            clientNumber: false,
          }}
          disableColumnResize={true}
          disableColumnSelector
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          paginationMode={'server'}
          rowCount={totalResults}
          onPaginationModelChange={(props) => {
            handlePageSizeChange(props.pageSize);
            handlePageModelChange(props.page);
            setExpandedRows([]);
          }}
          sortingMode={'server'}
          paginationModel={{ pageSize: pageSize, page: currentPageNumber }}
          sortModel={currentSortModel}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[25, 50]}
          localeText={{ toolbarFilters: "" }}
          autoHeight={true}
          rowHeight={98}
          pagination
          detailPanelExpandedRowIds={expandedRows}
          onDetailPanelExpandedRowIdsChange={setExpandedRows}
          disableVirtualization
          getDetailPanelContent={({ row }) => {
            return (
              <CustomNotesPanel changeData={changeData} row={row}></CustomNotesPanel>
            );
          }}
          getDetailPanelHeight={({ row }) => 'auto'}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoResultsOverlay,
            noResultsOverlay: CustomNoResultsOverlay
          }}

          slotProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
              filterHighlight
            },

          }}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: '#ffffff',
              borderRadius: 1,
              margin: 4,
              width: '91%',
              border: 0.25,
              borderColor: '#E5E5E5'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnHeadersInner': {
              margin: 4
            },
            '& .MuiDataGrid-detailPanelToggleCell': {
              padding: 3
            },
            '& .MuiDataGrid-detailPanel': {
              marginLeft: 4,
              marginRight: 5,
              width: '95.2%',
            },
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none'
            },
          }}
        />
        {renderResendInviteDialog()}
        {renderEmailChecklistDialog()}
      </Box>
    </>
  );
}

export default OrganizerList;