import * as IMG from "@utilities/constants/images";
import { YOUR_TAX_RETURN } from "@utilities/constants/strings";
import { taxReturnStatus } from "@utilities/constants/taxReturn";

const pendingTaxReturnStatuses = [
    taxReturnStatus.notSent,
    taxReturnStatus.sent,
    taxReturnStatus.partial
]

const hasPendingTaxReturnStatus = (taxReturnStatus) => {
    return pendingTaxReturnStatuses.includes(taxReturnStatus);
}

const getSwimlaneDetails = (props) => {
    const taxYear = props.taxYear ?? new Date().getFullYear();
    const cards = [
        //// TODO: to be replaced with actual card details in future stories.
        {
            title: `${taxYear} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-1",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            dashBoardIcon: IMG.ABOUT_YOUR_YEAR, // to be replaced.
            showBulkUpload: false,
            notes: [],
            statusDefault: 6,
            isRequired: true,
        },
        {
            title: `${taxYear} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-2",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            dashBoardIcon: IMG.ABOUT_YOUR_YEAR, // to be replaced.
            showBulkUpload: false,
            notes: [],
            statusDefault: 6,
            isRequired: true,
        },
        {
            title: `${taxYear} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-3",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            dashBoardIcon: IMG.ABOUT_YOUR_YEAR, // to be replaced.
            showBulkUpload: false,
            notes: [],
            statusDefault: 6,
            isRequired: true,
        },
        {
            title: `${taxYear} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-4",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            dashBoardIcon: IMG.ABOUT_YOUR_YEAR, // to be replaced.
            showBulkUpload: false,
            notes: [],
            statusDefault: 6,
            isRequired: true,
        },
        {
            title: `${taxYear} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-5",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            dashBoardIcon: IMG.ABOUT_YOUR_YEAR, // to be replaced.
            showBulkUpload: false,
            notes: [],
            statusDefault: 6,
            isRequired: true,
        },
        {
            title: `${taxYear} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-6",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            dashBoardIcon: IMG.ABOUT_YOUR_YEAR, // to be replaced.
            showBulkUpload: false,
            notes: [],
            statusDefault: 6,
            isRequired: true,
        }
    ];
    return {
        cards,
        entryType: 'TaxReturn',
        title: YOUR_TAX_RETURN
    };
}

export {
    hasPendingTaxReturnStatus,
    getSwimlaneDetails
}