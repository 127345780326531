import { GridListCell } from 'react-md';
import { isMobile } from 'react-device-detect';
import DashboardCard from '@components/dashboard/dashboardCard';
import { MOBILE_FRIENDLY_CARDS } from '@utilities/constants/responsive';

function DashboardGridCell(props) {
    const { card, index, isClient } = props;

    const defaultCellCard = (
        <GridListCell key={`category-card-${index}`} className="cardGridCell" colSpan={1}>
            <DashboardCard card={card} />
        </GridListCell>
    );

    if (!isMobile || !isClient) {
        return defaultCellCard;
    }

    if (MOBILE_FRIENDLY_CARDS.includes(card.title)) {
        return defaultCellCard;
    } else {
        return <GridListCell />;
    }
}

export default DashboardGridCell;
