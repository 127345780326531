
// common modules
import React, { useEffect, useState } from 'react';
import { ExpandMoreSVGIcon, ExpandLessSVGIcon } from '@react-md/material-icons';
import {
    Grid,
    GridCell,
    GridList,
    GridListCell,
    Divider,
    Button,
    TextIconSpacing
} from 'react-md';

// custom modules
import { getSwimlaneDetails } from '@utilities/services/taxReturnService';
import TaxReturnCard from '@components/dashboard/dashboardCard/taxReturnCard';

const _SupportedDashboardVersions = [2];

function TaxReturnSwimlane(props) {
    const {
        dashboardVersion,
        taxReturnStatus,
        taxYear,
        cardsPerRow
    } = props;

    const [isViewMore, setIsViewMore] = useState(true);

    const swimlaneDetails = getSwimlaneDetails({ taxYear });

    const enableViewToggle = swimlaneDetails.cards.length > cardsPerRow;

    const onViewToggleClick = () => {
        if (enableViewToggle) {
            // toggle view more will only be available if there are more than cardsPerRow cards is exceeded.
            setIsViewMore(!isViewMore);
        }
    }

    const filteredCards = isViewMore
        ? swimlaneDetails?.cards
        : swimlaneDetails?.cards.slice(0, cardsPerRow);

    return taxYear && taxReturnStatus && _SupportedDashboardVersions.some(v => v === dashboardVersion)
        ? (
            <GridCell key={`category-taxReturn`} colSpan={12} className="taxReturnSwimLane categoryCell" data-testid={'category-taxReturn'}>
                <GridCell key={`categoryTitle-taxReturn`} colSpan={12} className="dashboardCategoriesHeader">
                    <div className="dashboardCardHeaderTitle">
                        <h1 className="dashHeaderTitle">
                            {swimlaneDetails.title} <span>{swimlaneDetails.cards.length} {swimlaneDetails.cards.length > 1 ? 'Forms' : 'Form'}</span>
                        </h1>
                    </div>
                    <div className={`viewAllBtn`}>
                        <Button
                            id="combined-button-2"
                            className={`viewAllCards ${!enableViewToggle ? 'notClickable' : ''}`}
                            onClick={onViewToggleClick}
                            disabled={!enableViewToggle}
                        >
                            <TextIconSpacing
                                icon={isViewMore
                                    ? <ExpandLessSVGIcon className="expandCardsButton" />
                                    : <ExpandMoreSVGIcon className="expandCardsButton" />
                                }
                                iconAfter
                            >
                                {`View ${isViewMore ? 'Less' : 'More'}`}
                            </TextIconSpacing>
                        </Button>
                    </div>
                </GridCell>
                <GridCell colSpan={12} id="dashboardCategoryDivider">
                    <Divider />
                </GridCell>
                <GridList className="dashboardGridListCategory" maxCellSize={300} cellMargin="10px">
                    {
                        filteredCards.map((card, index) => {
                            return (
                                <GridListCell key={`category-card-${index}`} className="cardGridCell" colSpan={1}>
                                    <TaxReturnCard card={card} />
                                </GridListCell>
                            );
                        })
                    }
                </GridList>
            </GridCell>
        )
        : null;
}

export default TaxReturnSwimlane;