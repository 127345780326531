import { Card, Divider } from 'react-md';
import { getCardProp } from '@utilities/helpers/getCardProp';
import './dashboardCard.css';

function TaxReturnCard(props) {
    const { card, onCardClick } = props;

    let statusTitle = 'Not Started';
    card.statusTitle = statusTitle;
    let cardStyle = {};

    const onTaxReturnCardClick = () => {
        if (onCardClick) {
            onCardClick();
        }
    }

    return (
        <Card className={'cardContainer taxReturnCard'} onClick={onTaxReturnCardClick}>
            <div className="cardContent">
                <div className="cardTitle">
                    <div className="cardIconContainer" style={cardStyle}>
                        <img className="overHangImg" alt={`Icon for ${getCardProp(card.formName, 'title')} dashboard card`} src={card.dashBoardIcon} />
                    </div>
                    <div className="cardTitleContainer" style={cardStyle}>
                        <p>{card.title}</p>
                        <div className='cardSubtitle'>{getCardProp(card.formName, 'subtitle')}</div>
                    </div>
                </div>
            </div>
            <div className="cardStatusContainer" style={cardStyle}>
                <Divider />
                <div className="cardProgressLabelContainer">
                    <div>
                        <p style={{ margin: '5px 0 0 5px' }}>Download</p>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default TaxReturnCard;
