import { Card } from 'react-md';
import { useToggle } from '@react-md/utils';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as STR from '@utilities/constants/strings';

function EntryIcon(props) {
    const { CARDSTATUS } = usePageFramework();
    const { card } = props;

    // Set the initial state to true if card.hasPriorData is true, or based on other conditions
    const [isSelected, select, unSelect] = useToggle(card?.hasPriorData || card?.isRequired || card?.statusId === CARDSTATUS.REQUIRED);

    const getSectionName = (obj) => {
		return obj.worksheet?.[0]?.split('\\').pop() || null;
	};

    const sectionName = getSectionName(card);

    const updateStatus = () => {
        try {
            if (!card) {
                throw new Error("Card data is missing");
            }

            if (isSelected) {
                card.isRequired = false;
                card.isRecommended = false;
                unSelect();
            } else {
                // card.statusId = CARDSTATUS.REQUIRED;
                if (sectionName && ['Sch C - Business', 'Sch E, p 1 - Rent and Royalty', 'Sch F / 4835 - Farm'].includes(sectionName)) {
                    card.isRequired = true;
                }
                if (sectionName && !['Sch C - Business', 'Sch E, p 1 - Rent and Royalty', 'Sch F / 4835 - Farm'].includes(sectionName) || card.title === STR.ENERGY_EFFICIENT || card.title === STR.DEPENDENTS || card.title === STR.TAX_PAYMENTS) {
                    card.isRecommended = true;
                }
                select();
            }
        } catch (error) {
            console.error("Error updating card status: ", error.message);
        }
    };

    return (
        <div className="entryIconCard">
            <Card data-testid="entryNoShadowId" onClick={() => updateStatus()} className="entryNoShadow">
                <div className={`entryIconBg ${isSelected ? 'entryIconBgActive' : ''}`}>
                    <img 
                        className={card.formName === 'foreignIncome' ? 'entrIconForeign' : 'entryIcon'} 
                        alt={`Icon for ${card.title} in entry experience`} 
                        src={card.entryIconInactive} 
                    />
                </div>
                <div className={`entryIconName ${isSelected ? 'entryIconNameSelected' : ''}`}>
                    <div>{card.entryTitle}</div>
                </div>
            </Card>
        </div>
    );
}

export default EntryIcon;
